import { startTransition, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [userState, setUserState] = useState();

  const navigateToPath = (path = "/mypage") => {
    startTransition(() => {
      navigate(path);
    });
  };

  useEffect(() => {
    setUserState(sessionStorage.getItem("user"));
  }, [userState]);

  if (userState === "nomember") {
    return (
      <>
        <nav className="navbar navbar-light navbar-expand-lg navigation-clean-button">
          <title>軽貨物協会</title>{" "}
          <div className="container">
            <Link className="navbar-brand" to="/">
              <i className="fas"></i>&nbsp; 社）全国軽貨物協会（テスト版）
            </Link>
            <span className="navbar-text actions">
              <Link
                className="btn btn-light action-button"
                role="button"
                to="/terms"
              >
                新規登録
              </Link>
            </span>
          </div>
        </nav>
        <hr className="m-0" />
      </>
    );
  } else if (userState === "noadmin") {
    return (
      <>
        <nav className="navbar navbar-dark navbar-expand-lg bg-success bg-gradient navigation-clean-button">
          <title>管理者＿軽貨物協会</title>
          <div className="container">
            <Link
              className="navbar-brand"
              to="/admin"
              style={{ color: "var(--bs-white)" }}
            >
              <i className="fas"></i>&nbsp; 社）全国軽貨物協会
            </Link>
          </div>
        </nav>
        <hr className="m-0" />
      </>
    );
  } else if (userState === "member") {
    return (
      <>
        <nav className="navbar navbar-light navbar-expand-lg navigation-clean-button">
          <title>軽貨物協会</title>
          <div className="container">
            <p
              onClick={() => navigateToPath("/mypage")}
              className="navbar-brand custom-navbar-brand"
            >
              <i className="fas"></i>&nbsp; 社）全国軽貨物協会
            </p>
          </div>
        </nav>
        <hr className="m-0" />
      </>
    );
  } else if (userState === "admin") {
    return (
      <>
        <nav className="navbar navbar-dark navbar-expand-lg bg-success bg-gradient navigation-clean-button">
          <title>管理者＿軽貨物協会</title>
          <div className="container">
            <p
              onClick={() => navigateToPath("/admin_mypage")}
              className="navbar-brand custom-navbar-brand"
              style={{ color: "var(--bs-white)" }}
            >
              <i className="fas"></i>&nbsp; 社）全国軽貨物協会（テスト版）
            </p>
          </div>
        </nav>
        <hr className="m-0" />
      </>
    );
  }
};
export default Header;
